import { useState } from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import Label from "../../components/label";
import Iconify from "../../components/iconify";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  name,
  avatarUrl,
  requestMessage,
  status,
  handleClick,
  post,
  time,
  getPostDetails,
  approveHandler,
  rejectHandler,
  isApproveVisible,
  requestId,
  onPaymentProcessHandler,
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const renderStatusBatch = (status) => {
    switch (status) {
      case "Rejected":
        return (
          <>
            <Label color="error">Rejected</Label>
          </>
        );

        break;
      case "Done":
        return (
          <>
            <Label color="success">Finished</Label>
          </>
        );

        break;
      case "Pending":
        return (
          <>
            <Label color="secondary">Pending</Label>
          </>
        );

        break;
      case "Approved_Pending_Payment":
        return (
          <>
            <Label color="success">Approved and pending for payment</Label>
            <br />
            <Button
              variant="contained"
              onClick={() => onPaymentProcessHandler(requestId, getPostDetails)}
              sx={{ marginTop: 1 }}
            >
              Proccess to payment
            </Button>
          </>
        );

        break;

      default:
        return <Label>{status}</Label>;
        break;
    }
  };

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell padding="checkbox"></TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{requestMessage}</TableCell>

        <TableCell align="center">
          <Link to={`/post/${post?._id}`}>{post?.caption}</Link>
        </TableCell>

        <TableCell>{renderStatusBatch(status)}</TableCell>
        <TableCell>
          <ReactTimeAgo date={time} locale="en-US" />
        </TableCell>
        {isApproveVisible ? (
          <TableCell align="right" width={"20%"}>
            <Button
              size="small"
              variant="contained"
              color="success"
              sx={{ marginRight: 1 }}
              onClick={() => {
                approveHandler(requestId);
              }}
            >
              Approve
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={() => {
                rejectHandler(requestId);
              }}
            >
              Reject
            </Button>
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  company: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string,
};
