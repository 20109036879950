import SvgColor from "../../components/svg-color";
import MmsIcon from "@mui/icons-material/Mms";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HistoryIcon from "@mui/icons-material/History";
import PaymentIcon from "@mui/icons-material/Payment";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AddTaskIcon from "@mui/icons-material/AddTask";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "My Impact",
    path: "/",
    icon: icon("ic_analytics"),
  },
  {
    title: "Feed",
    path: "/feed",
    icon: icon("feed"),
  },
  {
    title: "Food Map",
    path: "/foodmap",
    icon: <MyLocationIcon />,
  },
  {
    title: "Post your food",
    path: "/createPost",
    icon: <AddCircleOutlineIcon />,
  },
  {
    title: "Chat",
    path: "/chat",
    icon: <QuestionAnswerIcon />,
  },
  {
    title: "My Requests",
    path: "/myrequest",
    icon: <HistoryIcon />,
  },
  {
    title: "User Request",
    path: "/approveRequest",
    icon: <AddTaskIcon />,
  },
  {
    title: "My Payment",
    path: "/transactions",
    icon: <PaymentIcon />,
  },
];

export default navConfig;
