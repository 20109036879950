import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
//utilities
import { io } from "socket.io-client";
import Cookies from "js-cookie";
import useFetch from "./hooks/useFetch.js";
import SERVER_URI from "./serverUri";
//redux
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "./features/userSlice.js";
import { setSocket } from "./features/socketSlice";
import { showModal } from "./features/modalSlice.js";
import {
  addMessages,
  clearMessage,
  deleteChat,
  updateChats,
} from "./features/messageSlice.js";
import { addOnline, getUsers } from "./features/usersSlice.js";
import { setPosts } from "./features/postSlice.js";
//components
import Auth from "./pages/Auth/Auth";
import Modal from "./components/Modal/Modal.jsx";
import Loading from "./components/Loading/Loading.jsx";
import Backdrop from "./components/Backdrop/Backdrop.jsx";
import { CommonRoutes } from "./routes";
import FoodRequest from "./components/FoodRequest/FoodRequest.jsx";

import Router, { LoginPage } from "../src/routes/sections";
import ThemeProvider from "../src/theme";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const dispatch = useDispatch();
  const customFetch = useFetch();
  const { user, isAuthenticated, isLoading: authLoader } = useAuth0();
  const [theme, setTheme] = useState("dark-contrast");
  const {
    user: { id, isGuest },
    modal: { isLoading, isSidebarVisible },
    socket: { socket },
    message: { to, conversationID },
  } = useSelector((state) => state);

  //login
  useEffect(() => {
    if (!authLoader) {
      // Ensure the authentication loading is done
      if (isAuthenticated) {
        // If user is authenticated
        if (user) {
          // Check if user is 'guest'

          const userCoockie = Cookies.get("user");

          // dispatch(
          //   login({
          //     name: user.name,
          //     profileImage: user.picture,

          //     isGuest: false,
          //   })
          // );
        } else {
          // Dispatch login if user is not 'guest'
          dispatch(logout());
        }
      } else {
        dispatch(logout()); // Logout if user is not authenticated
      }
    }
  }, [authLoader, isAuthenticated, user, dispatch]);

  //get users and chats and init socket
  useEffect(() => {
    if (id) {
      const query = `id=${id}`;
      dispatch(getUsers({ customFetch }));
      dispatch(setPosts({ customFetch }));
      if (!isGuest) dispatch(setSocket(io(SERVER_URI, { query })));
    }
  }, [id, customFetch, dispatch, isGuest]);

  //socket events
  useEffect(() => {
    if (socket) {
      socket.on("usersOnline", (users) => dispatch(addOnline(users)));
      socket.on("delete chat", (id) => dispatch(deleteChat(id)));
    }
  }, [socket, dispatch]);

  useEffect(() => {
    if (socket) {
      socket
        .off("receive message")
        .on("receive message", (message, senderID) => {
          dispatch(showModal({ msg: "1 new message" }));
          dispatch(
            updateChats({ lastMessage: message, id: senderID, customFetch })
          );
          senderID === to && dispatch(addMessages({ text: message }));
        });
      socket
        .off("clear chat")
        .on("clear chat", (id) =>
          dispatch(clearMessage({ conversationID: id }))
        );
    }
  }, [customFetch, dispatch, socket, to, conversationID]);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <Suspense>
          {id ? (
            <>
              <Router />
            </>
          ) : (
            <>
              <LoginPage />
            </>
          )}
        </Suspense>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
