import React from "react";
import { cloneElement } from "react";
import { createPortal } from "react-dom";
import "./backdrop.css";
import { Backdrop as MuiBackDrop } from "@mui/material";

const Backdrop = ({ children, show, display, onClose = () => {} }) => {
  const newChildren = cloneElement(children, {
    ...children.props,
    show: show ? "true" : undefined,
    onClose,
  });
  const closeModal = (e) => {
    const isBackdrop =
      e.target.firstChild?.classList?.contains("backdrop__content");
    if (isBackdrop) onClose();
  };

  const [open, setOpen] = React.useState(show);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const node = (
    <MuiBackDrop
      sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
      open={show}
      onClick={onClose}
    >
      {newChildren}
    </MuiBackDrop>
  );
  return createPortal(node, document.getElementById("backdrops"));
};

export default Backdrop;
