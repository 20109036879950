import { useEffect, useState } from "react";

import Slide from "@mui/material/Slide";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { bgBlur } from "../../../theme/css";

import Iconify from "../../../components/iconify";
import SearchResults from "../../../components/SearchResults/SearchResults";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { setIsLoading, toggleSidebar } from "../../../features/modalSlice";
import { fetchUsersService } from "../../../services/userServices";
import { fetchPostsService } from "../../../services/postServices";
import { Box, Card, Grid } from "@mui/material";

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled("div")(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const {
    user: { id, profileImage, isGuest },
    modal: { isSidebarVisible },
  } = useSelector((state) => state);

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState({});

  const dispatch = useDispatch();
  const customFetch = useFetch();

  const searchHandler = async (e) => {
    e.preventDefault();
    if (query.length > 0) {
      dispatch(setIsLoading(true));
      const { posts } = await customFetch(fetchPostsService, { query });
      const { users } = await customFetch(fetchUsersService, { query });
      setSearchResult({ posts, users });
      dispatch(setIsLoading(false));
    }
  };

  const reset = () => {
    setQuery("");
    setSearchResult({});
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("searchResult", searchResult);
  }, [searchResult]);
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              onChange={(e) => setQuery(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: "fontWeightBold" }}
            />
            <Button
              variant="contained"
              //onClick={handleClose}
              onClick={searchHandler}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                handleClose();
              }}
              sx={{ marginLeft: 1 }}
            >
              Close
            </Button>

            {(searchResult?.posts || searchResult?.users) && (
              <SearchResults searchResult={searchResult} reset={reset} />
            )}
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
